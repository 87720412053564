import React from "react";

const LogoStacked: React.FC<{
  iconClass: string;
  titleClass: string;
}> = ({ iconClass, titleClass }: { iconClass: string; titleClass: string }) => (
  <>
    {/** Stacked Logo Paths */}

    {/** U Icon Path */}
    <path
      className={iconClass}
      d="M38.35 0L16 12.9v29.53C16 56 27 67 40.58 67c4.5 0 8.72-1.2 12.34-3.3l18.16-10.46C78.23 49.5 83 42.04 83 33.44V2.53L78.64 0 60.78 10.32v32.1c0 1.38-.2 2.74-.46 4.07-.26 1.28-.66 2.54-1.17 3.76-.13.3-.28.62-.43.93-.54-.07-1.07-.16-1.6-.27-1.15-.23-2.28-.58-3.36-1.04-1.06-.45-2.08-1-3.04-1.65-.95-.64-1.84-1.38-2.65-2.2-.8-.8-1.55-1.7-2.2-2.63-.64-.96-1.2-1.98-1.64-3.04-.46-1.07-.8-2.2-1.04-3.34-.26-1.18-.38-2.4-.38-3.6V2.57L38.35 0zm10.07 60.96c-1.2.5-2.48.9-3.77 1.17-1.33.27-2.7.4-4.07.4s-2.73-.13-4.06-.4c-1.3-.27-2.56-.66-3.78-1.17-1.2-.5-2.34-1.13-3.42-1.86-1.06-.72-2.07-1.54-2.98-2.45-.9-.9-1.74-1.92-2.46-2.98-.73-1.08-1.35-2.22-1.86-3.4-.5-1.23-.9-2.5-1.17-3.78-.27-1.34-.4-2.7-.4-4.07V15.47l17.94-10.3v28.26c0 10.74 7.55 19.7 17.68 21.85-.4.47-.8.93-1.25 1.37-.9.9-1.92 1.73-3 2.45-1.06.73-2.2 1.35-3.4 1.86zm29.85-23.92c-.23 1.14-.58 2.27-1.04 3.34-.45 1.06-1 2.08-1.65 3.04-.64.94-1.38 1.83-2.2 2.64-.8.8-1.7 1.55-2.64 2.2-.96.63-1.98 1.2-3.04 1.63-1.08.45-2.2.8-3.36 1.03-.23.05-.46.1-.7.13 1-2.68 1.57-5.6 1.57-8.62V12.9l13.53-7.74v28.27c0 1.22-.22 2.43-.46 3.6z"
    />

    {/** "UDACITY" Path */}
    <path
      className={titleClass}
      d="M8.67 92.3c0 2-1.54 3.62-3.8 3.62-2.25 0-3.85-1.62-3.85-3.6V85H0v7.33c0 2.56 1.78 4.64 4.87 4.64 3.1 0 4.82-2.08 4.82-4.64V85H8.66v7.3zM21.6 85.17h-3.74v11.66h3.73c3.48 0 5.82-2.16 5.82-5.84 0-3.66-2.34-5.83-5.83-5.83zm-.13 10.48h-2.42v-9.47h2.42c2.85 0 4.76 1.74 4.76 4.8 0 3.1-1.9 4.67-4.76 4.67z M37.6 85.17l-4.75 11.66h1.25l1.26-3.1 5.27-1.1 1.7 4.2h1.24l-4.73-11.66H37.6zm.6 1.62h.03l1.95 4.76-4.3.87 2.32-5.64z M55.02 95.92c-2.76 0-4.7-2.25-4.7-5 0-2.74 1.94-4.84 4.67-4.84 2.17 0 3.52 1.28 3.52 1.28l.62-.84s-1.4-1.5-4.2-1.5c-3.3 0-5.82 2.55-5.82 5.9 0 3.37 2.44 6.05 5.88 6.05 2.86 0 4.45-1.8 4.45-1.8l-.66-.8s-1.43 1.55-3.78 1.55z M67 85.17h1.2v11.66H67z M75.17 86.18h4.25v10.65h1.2V86.18h4.24v-1h-9.7zM98.65 85.17L94.9 90.8h-.02l-3.75-5.63h-1.3l4.4 6.64v5.03h1.18V91.8l4.54-6.63z"
    />
  </>
);

export default LogoStacked;
