/*
 * Converts language-agnostic "directions" (start, end, top, bottom) into "placements" Popper.js understands (left, right, bottom, top).
 * Handles RTL permutations by detecting if the `body` tag has `dir=rtl` set, since Popper.js computes position using JS, not CSS.
 *
 * https://github.com/FezVrasta/react-popper
 */

import { useState, useEffect } from "react";

const useDirectionToPopperPlacement = (direction) => {
  const [convertedDirection, setConvertedDirection] = useState();

  useEffect(() => {
    const isRtl = getComputedStyle(document.body).direction === "rtl";
    const convertedDirection = getConvertedDirection(direction, isRtl);
    setConvertedDirection(convertedDirection);
  }, [direction]);

  return convertedDirection;
};

function getConvertedDirection(direction, isRtl) {
  const placement = { start: "left", end: "right" };

  if (direction === "start") {
    return isRtl ? placement.end : placement.start;
  } else if (direction === "end") {
    return isRtl ? placement.start : placement.end;
  } else {
    return direction;
  }
}

export default useDirectionToPopperPlacement;
