import React from "react";

const LogoDefault: React.FC<{
  iconClass: string;
  titleClass: string;
}> = ({ iconClass, titleClass }: { iconClass: string; titleClass: string }) => (
  <>
    {/** Regular Logo Paths */}

    {/** U Icon Path */}
    <path
      className={iconClass}
      d="M29 .5l1 .5v13c0 5.55-2.9 8.7-6 10.1l-7.6 4.4c-.07.02-.13.06-.2.1l-.07.04C14.48 29.54 12.68 30 11 30 6 30 0 26 0 18V6l2 1v11c0 8 6 10 9 10 1.86 0 4.87-.77 6.9-3.25C14.17 23.87 10 20.68 10 14V2.2L2 7 0 6l10-6 1 .5 1 .5v13c0 6.94 4.52 8.6 7.02 8.93.6-1.3.98-2.93.98-4.93V5l2 1v12c0 1.85-.32 3.5-.88 4.9C23.65 22.56 28 20.8 28 14V2.25L22 6l-2-1 8-5z"
    />

    {/** "UDACITY" Path */}
    <path
      className={titleClass}
      d="M57.6 17.24c0 2.55-2.1 4.4-4.97 4.4s-4.96-1.85-4.96-4.4v-9.4h-2v9.5c0 3.33 2.9 6.17 6.96 6.17 4.08 0 6.96-2.93 6.96-6.16v-9.5h-2zm16.3-9.4H68.5V23.5h5.37c4.46 0 7.53-3.03 7.53-7.83 0-4.7-3.07-7.83-7.54-7.83zm-.2 13.7h-3.2V9.8h3.2c3.36 0 5.75 2.25 5.75 5.87 0 3.92-2.48 5.78-5.76 5.88zm43.5.3c-3.6 0-5.97-2.64-5.97-6.17 0-3.52 2.48-6.07 5.96-6.07 2.77 0 4.46 1.57 4.46 1.57l.8-1.38s-1.8-1.87-5.47-1.87c-4.78 0-7.76 3.53-7.76 7.74 0 4.3 3.08 7.94 7.95 7.94 3.76 0 5.65-2.54 5.65-2.54l-1.1-1.18c-.1-.1-1.58 1.96-4.56 1.96zm14.88-14h2V23.5h-2zM143 9.8h4.97v13.7h2V9.8h4.95V7.83h-11.9m30.57 0L168.82 15l-4.87-7.16h-2.08l5.95 8.7v6.97h2v-6.95l5.85-8.7m-81.43 0L88.1 23.5h1.97l1.7-4.5 6.45-1.37 2.18 5.88h2L96.32 7.85h-2.08zm-1.9 9.3l3-7.26h.1l2.47 6.16-5.56 1.08z"
    />
  </>
);

export default LogoDefault;
