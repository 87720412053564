import React, { useState, useMemo, useRef, useEffect } from "react";
import classNames from "classnames";
import { usePopper } from "react-popper";
import { useDirectionToPopperPlacement } from "../../utils";
import { useCallback } from "react";

const generateId = (): string => {
  const chars = "acdefhiklmnoqrstuvwxyz0123456789".split("");
  let result = "";
  for (let i = 0; i < 6; i++) {
    const x = Math.floor(Math.random() * chars.length);
    result += chars[x];
  }
  return result;
};

export const PREFIX = "vds-tooltip";
const TOOLTIP_ID = `${PREFIX}_${generateId()}`;

/** Tooltip Props */

export interface TooltipProps {
  /**
   * Text to display inside the tooltip.
   * If you use HTML instead of a string, please ensure it includes proper a11y
   * labels/props.
   */
  content: string | React.ReactNode;

  /** The trigger element */
  trigger: JSX.Element;

  /**
   * Preferred display direction. Tooltip will recompute its placement
   * dynamically based on available browser space to prevent clipped text
   */
  direction?: "start" | "end" | "bottom" | "top";

  /** Toggle light theme for dark backgrounds */
  inverse?: boolean;

  /** Control prop to always show Tooltip */
  open?: boolean;
}

/**
 * Tooltip is a floating, non-actionable label used to explain a user interface
 * element or feature.
 */

const Tooltip: React.FC<TooltipProps> = ({
  content,
  trigger,
  direction = "bottom",
  inverse = false,
  open: controlledOpen = false
}: TooltipProps) => {
  // Setup open state
  const [uncontrolledOpen, setOpen] = useState(false);
  const open = controlledOpen || uncontrolledOpen;

  // Setup Popper
  const referenceElement = useRef(null);
  const popperElement = useRef(null);
  const arrowElement = useRef(null);
  const { attributes, state, styles, update } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement: useDirectionToPopperPlacement(direction),
      modifiers: [{ name: "arrow", options: { element: arrowElement.current } }]
    }
  );

  // Setup event listeners
  const handleOpen = useCallback((): void => {
    if (update) {
      update();
    }
    setOpen(true);
  }, [setOpen, update]);
  const handleClose = useCallback((): void => setOpen(false), [setOpen]);
  const eventProps = useMemo(
    () => ({
      onBlur: handleClose,
      onFocus: handleOpen,
      onMouseEnter: handleOpen,
      onMouseLeave: handleClose
    }),
    [handleClose, handleOpen]
  );

  useEffect(() => {
    if (update) {
      update();
    }
  }, [update]);

  // Setup classnames
  const className = classNames(PREFIX, inverse && `${PREFIX}--inverse`);
  const arrowClassName = classNames("vds-tail", inverse && "vds-tail--inverse");

  return (
    <div>
      {/** Tooltip Trigger */}
      <span
        aria-describedby={TOOLTIP_ID}
        ref={referenceElement}
        {...eventProps}
      >
        {trigger}
      </span>

      {/**
       * Tooltip
       * Visually hide (rather than remove from DOM) when not open
       * for accessibility purposes
       */}
      <div
        data-testid={`${PREFIX}__wrapper`}
        className={`${PREFIX}__wrapper`}
        data-open={open}
      >
        <span
          className={className}
          id={TOOLTIP_ID}
          ref={popperElement}
          role="tooltip"
          style={styles.popper as React.CSSProperties}
          data-placement={state?.placement}
          {...eventProps}
          {...attributes.popper}
        >
          <span
            className={arrowClassName}
            ref={arrowElement}
            style={styles.arrow as React.CSSProperties}
          />
          {content}
        </span>
      </div>
    </div>
  );
};

export default Tooltip;
