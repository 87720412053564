import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import classNames from "classnames";

const PREFIX = "vds-select";

export interface OptionType {
  value: string | number;
  label?: string;
}

export interface ActionType {
  action:
    | "select-option"
    | "deselect-option"
    | "remove-value"
    | "pop-value"
    | "clear"
    | "create-option";
  option: OptionType | undefined;
  name?: string;
  removedValue?: OptionType;
  removedValues?: OptionType[];
}

/** Select Props */
export interface SelectProps {
  /**
   * For use with `loadOptions`. If true, will cache the loaded
   * options until set to false.
   */
  cacheOptions?: boolean;

  /** Allows clearing the selected option */
  clearable?: boolean;

  /**
   * For use with `loadOptions`. If passed a list of options, it will use
   * those for initial rendering and only call `loadOptions` upon filtering.
   */
  defaultOptions?: OptionType[];

  /** Initial selected value (for uncontrolled components) */
  defaultValue?: string | number | OptionType | OptionType[];

  /** Indicates the Select is not available for interaction */
  disabled?: boolean;

  /** Hide label visually */
  hiddenLabel?: boolean;

  /** Unique ID to associate the label with the Select */
  id: string;

  /**
   * Text label associated with the Select (required for a11y).
   * If you use HTML instead of a string, please ensure it includes proper a11y
   * labels/props.
   */
  label: string | React.ReactNode;

  /** Function for loading options asyncronously */
  loadOptions?(
    inputValue: string,
    callback?: (options: OptionType[]) => void
  ): Promise<OptionType[]>;

  /** Enables multiselect */
  multiselect?: boolean;

  /** Callback when Select loses focus */
  onBlur?(event: React.FocusEvent<HTMLElement>): void;

  /**
   * Callback when Select value changes
   * The `metadata` argument looks like:
   * {
   *   action: "select-option" | "deselect-option" |
   *     "remove-value" | "pop-value" | "set-value" |
   *     "clear";
   *   option: Object;
   *   removedValue: Object;
   *   name: string;
   * }
   */
  onChange?(value: OptionType | OptionType[] | null, action?: ActionType): void;

  /** Callback when Select receives focus */
  onFocus?(event: React.FocusEvent<HTMLElement>): void;

  /** Indicates whether the menu should be open or closed. Only necessary when wanting to control that yourself */
  open?: boolean;

  /** List of options to choose from */
  options?: OptionType[];

  /** Text displayed next to label for non-required fields. Only use this prop to internationalize the default string */
  optionalLabel?: string;

  /** Placeholder text to show in the select  */
  placeholder?: string;

  /** Indicates that a user must select a value before submitting */
  required?: boolean;

  /** Allows searching options by typing in the input */
  searchable?: boolean;

  /** A unique value for `data-testid` to serve as a hook for automated tests */
  testID?: string;

  /** The `FormValidation` component */
  validation?: JSX.Element;

  /** Controlled selected value (for controlled components). Requires `onChange` handler */
  value?: string | number | OptionType | OptionType[];
}

/** Select is a control for selecting a single choice from a list of four or more options. */

const VdsSelect: React.FC<SelectProps> = ({
  id,
  cacheOptions,
  clearable,
  defaultOptions,
  defaultValue,
  disabled,
  hiddenLabel,
  label,
  loadOptions,
  multiselect,
  onBlur,
  onChange,
  onFocus,
  open,
  options,
  optionalLabel = "(Optional)",
  placeholder = "",
  required,
  searchable = true,
  testID,
  validation,
  value
}: SelectProps) => {
  /**
   * If the validation prop is present, we assume there is an
   * error, and can apply proper styles based on that.
   */
  const isError =
    (validation && validation.props.variant === undefined) ||
    (validation && validation.props.variant === "error");

  const className = classNames(
    PREFIX,
    isError && `${PREFIX}--error`,
    !isError && validation && `${PREFIX}--${validation.props.variant}`
  );

  const labelClassName = classNames(
    `${PREFIX}__label`,
    hiddenLabel && `vds-visually-hidden`
  );

  /**
   * Find the longest option to set input size.
   * This is a fix for the select not sizing itself correctly
   * when it's inline or with a flex parent.
   */
  let longestOption = "";

  /**
   * Normalize options, defaultValue and value to maintain backwards
   * compatibility with the previous API
   */
  const normalizedOptions =
    options?.map((o) => {
      const optionLabel = o.label || String(o.value);
      longestOption =
        optionLabel.length > longestOption.length ? optionLabel : longestOption;
      return {
        value: o.value,
        label: optionLabel
      } as OptionType;
    }) || [];
  const normalizeValue = (
    val?: string | number | OptionType | OptionType[]
  ): OptionType | OptionType[] => {
    if (Array.isArray(val)) {
      return val;
    }
    return normalizedOptions.find((o) => {
      return typeof val === "object" ? o.value === val.value : o.value === val;
    }) as OptionType;
  };
  const normalizedDefault = defaultValue
    ? normalizeValue(defaultValue)
    : undefined;
  const normalizedValue = normalizeValue(value);

  /**
   * Manage value state for the hidden input that handles
   * validation when `required` is true.
   * https://github.com/JedWatson/react-select/issues/3140
   */
  const [checkValue, setCheckValue] = React.useState(
    typeof value === "string" ? value : JSON.stringify(value)
  );
  const handleOnChange = (
    value: OptionType | OptionType[] | null,
    action: ActionType
  ) => {
    if (required) {
      setCheckValue(JSON.stringify(value));
    }
    if (onChange) {
      onChange(value, action);
    }
  };

  const sharedProps = {
    "aria-label": typeof label === "string" ? label : label?.toString(),
    blurInputOnSelect: multiselect ? false : true,
    classNamePrefix: PREFIX,
    defaultValue: normalizedDefault,
    id,
    isClearable: clearable,
    isSearchable: searchable,
    isMulti: multiselect,
    isDisabled: disabled,
    menuIsOpen: open,
    onBlur,
    onChange: handleOnChange,
    onFocus,
    options: normalizedOptions,
    placeholder,
    required,
    value: normalizedValue
  };
  // Filter out undefined props
  const filteredProps = {};
  Object.keys(sharedProps).forEach((key) => {
    const value = sharedProps[key];
    if (value !== undefined) {
      filteredProps[key] = value;
    }
  });

  return (
    <div className={className} data-testid={testID}>
      <label id={`${id}__label`} className={labelClassName} htmlFor={id}>
        {label}
        {!required && (
          <span className={`${PREFIX}__optional`}>{`${optionalLabel}`}</span>
        )}
      </label>
      {loadOptions ? (
        <AsyncSelect
          {...filteredProps}
          cacheOptions={cacheOptions}
          defaultOptions={defaultOptions || true}
          loadOptions={loadOptions}
        />
      ) : (
        <Select {...filteredProps} />
      )}
      {validation}
      {/*
       * Hidden input to handle validation when `required` is set
       * https://github.com/JedWatson/react-select/issues/3140
       */}
      <input
        className={`${PREFIX}__hidden-input`}
        tabIndex={-1}
        autoComplete="off"
        size={longestOption.length + 6} // +6 accounts for the size of the dropdown indicator
        value={checkValue}
        required={required}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          setCheckValue(e.target.value);
        }}
      />
    </div>
  );
};

export default VdsSelect;
